<style>
    .template-editor .CodeMirror {
        height: 100%;
    }
</style>

<template>
    <modal name="templateEdit" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
        <CloseModal :close="closeEdit" />
        <div class="modalbox">
            <div class="modalbox-content">
                <h2>
                    {{ arquivo.nome_exibicao }}
                    <CButton @click="enterFullScreen" color="primary" size="sm" class="pull-right">
                        Editar em tela cheia
                    </CButton>
                </h2>
                <div class="template-editor">
                    <codemirror
                        id="codemirror"
                        v-model="arquivo.conteudo"
                        :options="code_options"
                        @input="modificado = true">
                    </codemirror>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <CButton color="success" @click="update">
                Salvar alterações
            </CButton>
            <CButton color="muted" @click="closeEdit">
                Descartar alterações
            </CButton>
        </div>
    </modal>
</template>

<script>
  import CloseModal from "../../components/CloseModal";

  import { codemirror } from 'vue-codemirror'

  // theme css
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/monokai.css'
  import 'codemirror/addon/display/fullscreen.css'

  // language js
  import 'codemirror/addon/selection/active-line.js'
  import 'codemirror/addon/display/fullscreen.js'
  import 'codemirror/mode/htmlmixed/htmlmixed.js'

  // autoCloseTags
  import'codemirror/addon/edit/closetag.js'

  export default {
    name: "Edit",
    components: {
      CloseModal,
      codemirror
    },
    props: [
      'arquivo',
      'update'
    ],
    data() {
      return {
        modificado: false,
        code_options: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: 'htmlmixed',
          lineWrapping: true,
          theme: 'monokai',
          showCursorWhenSelecting: true,
          viewportMargin: '50',
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        }
      }
    },
    watch: {
      'item.conteudo'() {
        this.modificado = true;
      },
    },
    methods: {
      enterFullScreen() {
        let element = document.querySelector("#codemirror");
        element.requestFullscreen()
      },
      confirmClose() {
        this.modificado = false;
        this.$modal.hide('templateEdit');
      },
      closeEdit() {
        if (this.modificado) {
          this.$swal({
            title: 'Descartar alterações ?',
            text: 'Essa ação não poderá ser desfeita',
            showCancelButton: true,
            icon: 'warning',
          })
          .then((res) => {
            if (res.isConfirmed) {
              this.confirmClose();
            }
          });
          return;
        }
        this.confirmClose();
      }
    }
  }
</script>